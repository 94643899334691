@import '../../../../scss/variables.scss';

// Global button definition
.seBtn {
	background-color: var(--brand-color-red);
	border: none;
	font-size: 12px;
	font-weight: 500;
	padding: 7px 11px;
	color: var(--brand-color-white);
	border-radius: var(--const-border-radius);
	display: inline-flex;
	align-items: center;
	transition: background-color linear 0.1s;

	// ELEMENTS
	.seBtn__icon {
		width: auto;
		height: auto;
		font-size: 16px;
	}

	.seBtn__text {
		margin-left: 0.5rem;
	}

    .seBtn__drop-down {
        margin-left: 0.25rem;
        margin-right: -0.5rem;
    }
	// SIZE MODIFIERS
	&--large {
		font-size: 16px;
		font-weight: 500;
		padding: 10px 25px;

		&.icon-only {
			padding: 10px 1rem;
		}

		.seBtn__text {
			margin-left: 1.5rem;
		}

		.seBtn__icon {
			font-size: 24px;
		}

        .seBtn__drop-down {
            margin-left: 0.5rem;
            margin-right: -1rem;
        }
	}
	&--small {
		min-height: 34px;
	}
	// COLOR MODIFIERS
	&--green {
		background-color: var(--brand-color-light-green);

		&:hover:enabled {
			background-color: var(--brand-color-light-green-darker-10);
		}
		&:disabled {
			background-color: var(--brand-color-light-green-lighter-75);
		}
	}

	&--grey {
		background-color: var(--brand-color-grey);

		&:hover:enabled {
			background-color: var(--brand-color-grey-darker-10);
		}
		&:disabled {
			background-color: var(--brand-color-light-grey);
		}
	}

	&--yellow {
		background-color: var(--brand-color-yellow);

		&:hover:enabled {
			background-color: var(--brand-color-yellow-darker-10);
		}
		&:disabled {
			background-color: var(--brand-color-light-grey);
		}
	}

	&--red {
		background-color: var(--brand-color-red);

		&:hover:enabled {
			background-color: var(--brand-color-red-darker-10);
		}
		&:disabled {
			background-color: var(--brand-color-light-grey);
		}
	}

	&--purple {
		background-color: var(--brand-color);

		&:hover:enabled {
			background-color: var(--brand-color-dark);
		}
		&:disabled {
			background-color: var(--brand-color-light);
		}
	}
}
