@import '../variables.scss';

// Overall Table
.ax-table {
  width: 100%;
  border: 1px solid var(--brand-color-grey) !important;
  margin-bottom: 20px;

  // Table Header
  thead {
    tr {
      height: 48px !important;
      background-color: var(--brand-color) !important;
      color: var(--brand-color-white) !important;
      th {
        font-weight: 500;
        font-size: 14px;
        .mat-sort-header-container {
          display: flex;
          align-items: center;
        }
        &.right {
          .mat-sort-header-container {
            justify-content: flex-end;
            text-align: center;
          }
        }
        &.center {
          .mat-sort-header-container {
            align-items: center;
            justify-content: center;
          }
        }
        &.left {
          .mat-sort-header-container {
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  // Table body row styling + hover effects
  tbody {
    tr {
      transition: background-color linear 0.1s;
      background-color: var(--brand-color-white);

      &.selectable-row {
        cursor: pointer;
        &:hover {
          background-color: var(--table-alternate-row-color-darker-2);
          &:nth-child(even) {
            background-color: var(--table-alternate-row-color-darker-5);
          }
        }
      }

      &:nth-child(even) {
        background-color: var(--table-alternate-row-color);
      }

      td {
        &.left {
          text-align: left;
        }
        &.right {
          text-align: right;
        }
        &.center {
          text-align: center;
        }
      }
    }
  }

    // Sort arrows
    .mat-sort-header-arrow {
        color: var(--brand-color-white) !important;
    }
}

// Summary above a table
.table-summary {
  background-color: var(--brand-color-off-white);
  margin-left: -16px !important;
  margin-right: -16px !important;
}

// widget table changes
.widget-data-table {
  border: none;
  border-collapse: separate;
  thead {
    tr {
      height: 2.5rem !important;
      background-color: var(--brand-color-white) !important;
      th {
        &:not(:first-of-type):not(.bar) {
          padding-left: 0.5rem;
        }
        &:not(:last-of-type) {
          padding-right: 0.5rem;
        }
        &.number {
          text-align: right;
        }
      }
    }

    .mat-header-cell {
      color: var(--brand-color-black);
    }
  }

  tbody {
    tr {
      height: 1.5rem;
      td {
        &:not(:first-of-type):not(.bar) {
          padding-left: 0.5rem;
        }
        &:not(:last-of-type) {
          padding-right: 0.5rem;
        }
        &.number {
          text-align: right;
        }
        &.bar {
          padding-right: 0;
        }
        .innerBar {
          height: 1.5rem;
          background-color: var(--brand-color-light-green);
        }
      }
    }
  }
}
