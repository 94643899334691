@import '../variables.scss';

.se-card {
	border-radius: 0 !important;
	padding: 0 !important;

	mat-card-title {
		font-size: 14px !important;
		font-weight: 500 !important;
		color: var(--brand-color-white);
		background-color: var(--brand-color) !important;
		min-height: 40px;
		padding: 0 20px 0 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	mat-card-content {
		padding: 0.75rem 1.5rem !important;

		h3 {
			margin-top: 20px;
			color: var(--brand-color);
			margin-bottom: 0;
			font-size: 11pt;
			font-weight: 700;
		}

		&__no-padding {
			padding: 0 !important;
			margin-top: -8px;
		}

		.table {
			thead {
				tr {
					background-color: var(--table-alternate-row-color);
					text-transform: uppercase;
					font-size: 10pt;
					color: var(--brand-color-dark-grey);
				}
			}

			tr {
				td {
					vertical-align: middle;

					&.button-column {
						display: flex;
						justify-content: flex-end;
						gap: 10px;
					}
				}
			}
		}
	}

	mat-card-footer {
		background-color: var(--table-alternate-row-color);
		margin: 0 !important;
		padding: 10px 15px 5px 15px;
		border-top: 1px solid var(--brand-color-light-grey);
		border-left: 1px solid var(--brand-color-light-grey);
		border-right: 1px solid var(--brand-color-light-grey);
	}
}
