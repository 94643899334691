mat-checkbox.mat-accent {
	--mdc-checkbox-selected-icon-color: var(--brand-color-light-green);
	--mdc-checkbox-selected-focus-icon-color: var(--brand-color-light-green);
	--mdc-checkbox-selected-hover-icon-color: var(--brand-color-light-green);
	--mdc-checkbox-selected-pressed-icon-color: var(--brand-color-light-green);
	--mdc-checkbox-selected-focus-state-layer-color: var(--brand-color-light-green);
	--mdc-checkbox-selected-pressed-state-layer-color: var(--brand-color-light-green);
	--mdc-checkbox-selected-hover-state-layer-color: var(--brand-color-light-green);

	--mdc-checkbox-selected-checkmark-color: var(--brand-color-white);
}
