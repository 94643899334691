@import '../variables.scss';

// Generic Styling for Login Shell Form Components
.loginShellForm {

	label {
		color:  var(--brand-color-white);
		font-size: 14px;
		font-weight: 500;
		margin-left: 10px;
		margin-bottom: 0.5rem;
	}

	input[type="email"],
	input[type="password"] {
		border-radius: 0;
		border: 0;
		font-size: 18px;
	}

	a {
		color:  var(--brand-color-grey);
		transition: color linear 0.1s;

		&:hover {
			color:  var(--brand-color-white);
		}
	}
}
