$const-border-radius: 5px;

$breakpoint-phone: 576px;
$breakpoint-tablet: 768px;
$breakpoint-small: 992px;
$breakpoint-large: 1200px;

// MIX-INS
@mixin fromTablet {
  @media screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin fromSmallScreen {
  @media screen and (min-width: $breakpoint-small) {
    @content;
  }
}

@mixin fromLargeScreen {
  @media screen and (min-width: $breakpoint-large) {
    @content;
  }
}
