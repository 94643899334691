@import 'variables.scss';

.icon-text {
  font-size: 14px;
  color: white;
  font-weight: 300;
  font-family: var(--fontFamily);
}

.icon-title {
  font-size: 28px;
  font-weight: 500;
  font-family: var(--fontFamily);
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

.icon-subTitle {
  font-size: 11px;
  font-weight: bold;
  opacity: 38%;
  font-family: var(--fontFamily);

  @media screen and (min-width: 992px) {
    font-size: 14px;
  }
}

//Admin Home Page Module Boxes
.navigationTile-titleContainer-title {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--fontFamily);
  margin-bottom: 11px;

  @media screen and (min-width: 992px) {
    font-size: 11px;
    font-weight: 400;
    font-family: var(--fontFamily);
    margin-bottom: 11px;
  }
}

.sectionHeader {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 11px;
}

.small-link {
  font-size: 14px;
  font-weight: 500;
  color: var(--brand-color-light);
  text-decoration: none !important;
}

.small-link:hover {
  color: white;
}

.textSemibold {
  font-size: 14px;
  font-weight: 500;
}

.heading {
  font-size: 24px;
  font-weight: 500;
}

.err-text {
  font-size: 14px;
  font-family: var(--fontFamily);
}

.map-panel-h1 {
  font-size: 14px;
  font-weight: 700;
  color: var(--brand-color);
}

.map-panel-h2 {
  font-size: 11px;
  font-weight: 500;
}

.filter-box {
  font-size: 12px;
}

.map-panel-list-title {
  font-size: 11px;
  font-weight: 500;
  color: var(--brand-color);
}

.map-panel-section-title {
  font-size: 11px;
  font-weight: 500;
  color: var(--brand-color);
}

.text-grey {
  color: var(--brand-color-grey) !important;
}

.text-dark-grey {
  color: var(--brand-color-dark-grey) !important;
}
