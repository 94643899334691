@media only screen and (max-width: 992px) {
	.mat-mdc-menu-content:first-child div {
		margin: 0px;
	}

	.mat-mdc-menu-panel {
		margin: 29px 0px 0px 0px;
	}
}

@media only screen and (min-width: 992px) {
	.mat-mdc-menu-panel {
		margin: 11px 0px 0px 0px;
	}
}

.mat-mdc-menu-panel {
	max-width: none !important;
}

.user-menu {
	background-color: var(--brand-color-medium) !important;
	width: 260px;
}
