.snackbar-success-message {
	--mdc-snackbar-container-color:  var(--brand-color-green);
	--mdc-snackbar-supporting-text-color:  var(--brand-color-white);
}

.snackbar-error-message {
	--mdc-snackbar-container-color: var(--brand-color-red);
	--mdc-snackbar-supporting-text-color:  var(--brand-color-white);
}

.snackbar-warning-message {
	--mdc-snackbar-container-color:  var(--brand-color-orange);
	--mdc-snackbar-supporting-text-color:  var(--brand-color-white);
}
