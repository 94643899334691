@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4,
  8: $spacer * 5,
);
@import 'bootstrap/scss/bootstrap';
