.nid-modal__tab-panel{

	padding: 15px;
	background-color: var(--bg-color);
	position: relative;

	.status-ind{
		position: absolute;
		top: 15px;
		right: 15px;
		padding: 5px 10px;
		border-radius: var(--const-border-radius);
		text-transform: uppercase;
		color: var(--brand-color-white);
		font-size: 12px;
	}

	// Section Titles
	.sectionTitles{
		font-size: 10pt;
		color: var(--brand-color);
		font-weight: 700;
		margin: 0;
		text-transform: uppercase;
		padding: 0;
	}

	.info-box{
		margin-bottom: 20px;
		margin-top: 10px;

		// Used to wrap the header so you can have icons on the right
		&__headerwrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
		}

		// Styling of right aligned icons
		&__icons {
			color: var(--brand-color);

			mat-icon {
				margin-left: 10px;
				cursor: pointer;

				&:hover {
					background-color: #ddd;
					border-radius: 50%;
				}
			}
		}

		// Styling of header
		&__header {
			background-color: var(--brand-color-medium);
			color: var(--brand-color-white);
			display: flex;
			align-items: center;
			width: 230px;
			padding: 6px 10px;
			border-top-left-radius: var(--const-border-radius);
			border-top-right-radius: var(--const-border-radius);

			mat-icon {
				height: 18px;
				width: 18px;
				font-size: 18px;
			}

			span {
				margin-left: 10px;
				font-size: 12px;
			}
		}

		// Content of an info box
		&__content {
			border: 1px solid var(--brand-color-medium);
			border-radius: var(--const-border-radius);
			border-top-left-radius: 0;
			overflow: hidden;
		}

		// Table wraper for showing tabular content
		&__table-wrapper {
			display: grid;
			grid-template-columns: 1fr;

			@include fromSmallScreen() {
				grid-template-columns: 2fr 2fr;
			}

			;
			overflow: hidden;

			dl {
				display: grid;
				grid-template-columns: 1fr 2fr;
				margin: 0;

				dt {
					//background-color: lighten( var(--brand-color-light), 25%);
					padding: .5em 10px;
					text-align: right;
					text-transform: uppercase;
					font-size: 10px;
					font-weight: 700;
					margin: 0;
					color: var(--brand-color-medium);
				}

				dd {
					padding: .5em 10px;
					margin: 0;
					background-color: var(--brand-color-white);
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			&--top-border {
				//border-top: 1px solid lighten( var(--brand-color-light), 25%);
			}

			&--condensed {
				dl {
					grid-template-columns: 1fr 1fr;
				}
			}
		}
	}
}


.nid-modal__split-view{

	--split-padding: 15px;

	display: grid;
	grid-template-columns: 1fr 2fr;
	padding: 15px 0;

	&--list{
		padding: var(--split-padding);
		border-right: 1px solid rgba(87, 49, 140, 13%);
		ul{
			list-style: none;
			margin: 0 calc(-1 * var(--split-padding)) 20px calc(-1 * var(--split-padding));
			padding: 0;
			display: block;

			li{
				border: 1px solid rgba(87, 49, 140, 13%);
				margin: 0;
				display: flex;
				gap: 20px;
				align-items: center;
				border-left: 8px solid #fff;
				cursor: pointer;
				justify-content: space-between;

				background-color: #fff;

				&:hover{
					background-color: #f5f5f5;
				}

				&:nth-child(even){
					background-color: rgba(218, 212, 255, 12%);
					border-left-color: rgba(218, 212, 255, 12%);

					&:hover{
						background-color: rgba(218, 212, 255, 32%);
					}
				}

				span.text-container{
					font-size: 10pt;
					display: block;
					width: 100%;
					padding: 7px 10px;
					span.name{
						font-weight: 700;
						display: block;
					}
					span.type{
						color: #666;
					}
				}

				mat-icon{
					font-weight: 700;
					color: #E73232;
					margin-right: 10px;
					transform: scale(0.8);

					&:hover{
						transform: scale(1.2);
					}
				}

				&.active{
					border-left-color: var(--brand-color);
				}
			}
		}
	}

	&--content{
		padding: var(--split-padding);
	}
}

.nidModal {
	content {
		padding: 0 !important;
	}
	section {
		margin-top: 0 !important;
	}
}
