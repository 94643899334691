@media only screen and (max-width: 992px) {

	.mat-menu-content:first-child div {
		margin: 0px;
	}

	.mat-menu-panel {
		margin: 29px 0px 0px 0px;
	}

	.nav-pills {
		flex-wrap: nowrap;
		overflow-x: auto;
	}
}

.tab-contents .tab-contents__section:last-child {
	margin-bottom: 150px;
}
