@import '../variables.scss';

$popup-color: var(--bg-color);
$arrow-hint-display: block;

// Custom Mapbox GL POPUP styling
.map-popup {
    max-width: 1000px !important;

    &.mapboxgl-popup-anchor-right {
        .mapboxgl-popup-tip {
            border-left-color: $popup-color;
            display: $arrow-hint-display;
        }
    }

    &.mapboxgl-popup-anchor-bottom {
        .mapboxgl-popup-tip {
            border-top-color: var(--brand-color-dark-grey);
            display: $arrow-hint-display;
        }
    }

    &.mapboxgl-popup-anchor-top {
        .mapboxgl-popup-tip {
            border-bottom-color: var(--brand-color);
            display: $arrow-hint-display;
        }
    }

    &.mapboxgl-popup-anchor-left {
        .mapboxgl-popup-tip {
            border-right-color: $popup-color;
            display: $arrow-hint-display;
        }
    }
    // Contents of popup
    .mapboxgl-popup-content {
        background-color: $popup-color;
        border-radius: var(--const-border-radius);
        border: 1px solid var(--brand-color-grey);
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
        padding: 0;
        overflow: hidden;
    }
    // Header
    &__header {
        background-color: var(--brand-color);
        color: var(--brand-color-white);
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
    }

    &__content {
        font-size: 12px;
        color: var(--brand-color-black);
        // NID Site Popup Specific
        &--nidSite {
            padding: 10px;

            .address {
                display: block;
            }

            .status {
                display: block;
            }
        }

        &--table {
            .data-row {
                display: flex;
                min-width: 300px;

                span {
                    flex: 1 1;
                    padding: 3px 8px;

                    &:nth-child(2) {
                        max-width: 200px;
                        font-weight: 700;
                        text-align: right;
                    }

                    &.wider {
                        max-width: none;
                    }
                }

                &:nth-child(odd) {
                    span {
                        background-color: var(--brand-color-off-white);
                    }
                }
            }

            $bar-height: 5px;

            .percentage {
                height: $bar-height;
                width: 100%;
                background-color: var(--brand-color-off-white);
                margin-bottom: 3px;

                div {
                    background-color: var(--brand-color-light-green);
                    height: $bar-height;
                }
            }
        }
    }

    &__footer {
        background-color: var(--brand-color-light-grey);
        color: var(--brand-color-metal);
        padding: 2px 10px;
        font-size: 9px;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
    }
}

// Custom styling for a map data layer modal popup
.data-popup {
    padding: 20px;

    &__section {
        h2 {
            margin: 0;
            font-size: 16px;
            background-color: var(--bg-color-darker-5);
            font-weight: 700;
            color: var(--brand-color);
            padding: 5px 10px;
        }
    }

    &__table {
        width: 100%;
        background-color: var(--bg-color);
        margin-bottom: 20px;
        border: 1px solid var(--brand-color-off-white);
        $table-padding: 5px 10px;

        thead {
            tr {
                background-color: var(--bg-color-darker-5);

                th {
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: $table-padding;
                    text-align: right;
                    color: var(--brand-color-black);

                    &:first-child {
                        width: auto;
                        text-align: left;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: $table-padding;
                    width: 75px;
                    text-align: right;

                    &:first-child {
                        width: auto;
                        text-align: left;
                    }
                }

                &:nth-child(odd) {
                    background-color: var(--brand-color-white);
                }
            }
        }
    }
}
