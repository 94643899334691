@use '@angular/material' as mat;

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$ax-typography: mat.define-typography-config(
  $headline-6:
    mat.define-typography-level(
      $font-size: 20px,
      $line-height: 32px,
      $font-weight: 400,
      $letter-spacing: 0.0125em,
    ),
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($ax-typography);
@include mat.all-component-typographies($ax-typography);

@include mat.core();

// Importing Bootstrap SCSS file
@import 'scss/bootstrap.scss';

// Custom Style Imports
@import 'scss/variables.scss';
@import 'scss/typography.scss';

@import 'scss/components/tables.scss';
@import 'scss/components/cards.scss';
@import 'scss/components/checkbox.scss';
@import 'scss/components/snackbar.scss';
@import 'scss/components/tabs.scss';
@import 'scss/components/menu.scss';
@import 'scss/components/dialog.scss';
@import 'scss/components/nid-modal.scss';

@import 'scss/modules/login-shell.scss';
@import 'scss/modules/map.scss';
@import './app/shared/components/icon-button/icon-button.component.scss';

// Global styles not specific to a component or module
html,
body {
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  background-color: #f6f6ff;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.error-space {
  min-height: 42px;
  border-radius: var(--const-border-radius);
}

.error-panel-margin {
  margin-bottom: 20px;
}

.negativeMT18 {
  margin-top: -18px;
}

.content-mt {
  margin-top: 22px !important;
}
.err-bg-color {
  background-color: #e73232;
}

a {
  text-decoration: none !important;
}

.not-found {
  background-color: lightyellow;
  padding: 5px 10px;

  font-style: italic;
  font-size: 11px;
  font-weight: 700;
}

.container-fluid {
  &--sePadding {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (min-width: $breakpoint-tablet) {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (min-width: $breakpoint-small) {
      padding-top: 40px;
      padding-left: 25px;
      padding-right: 25px;
    }

    @media screen and (min-width: $breakpoint-large) {
      padding-top: 70px;
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  &--noPadding {
    padding: 0px;
  }
}

.spinner-overlay {
  display: flex;
  justify-content: center;
  padding-top: 5em;
}

.spinner {
  display: flex;
  justify-content: center;
}

:root {
  --border-radius: 0.25em;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  --surface-0: #ffffff;
  --surface-50: #f6f6ff; // Custom colour
  --surface-100: #f5f5f5;
  --surface-200: #e9e9f5;
  --surface-300: #e0e0e0;
  --surface-400: #c4c4c9; // Custom colour
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --content-padding: 1rem;
  --inline-spacing: 0.5rem;
  --blue-50: #f4fafe;
  --blue-100: #cae6fc;
  --blue-200: #a0d2fa;
  --blue-300: #75bef8;
  --blue-400: #4baaf5;
  --blue-500: #2196f3;
  --blue-600: #1c80cf;
  --blue-700: #1769aa;
  --blue-800: #125386;
  --blue-900: #0d3c61;
  --green-50: #f6fbf6;
  --green-100: #d4ecd5;
  --green-200: #b2ddb4;
  --green-300: #90cd93;
  --green-400: #6ebe71;
  --green-500: #64bc46; // Custom colour
  --green-600: #419544;
  --green-700: #357b38;
  --green-800: #2a602c;
  --green-900: #1e4620;
  --yellow-50: #fffcf5;
  --yellow-100: #fef0cd;
  --yellow-200: #fde4a5;
  --yellow-300: #fdd87d;
  --yellow-400: #fccc55;
  --yellow-500: #fbc02d;
  --yellow-600: #d5a326;
  --yellow-700: #b08620;
  --yellow-800: #8a6a19;
  --yellow-900: #644d12;
  --cyan-50: #f2fcfd;
  --cyan-100: #c2eff5;
  --cyan-200: #91e2ed;
  --cyan-300: #61d5e4;
  --cyan-400: #30c9dc;
  --cyan-500: #00bcd4;
  --cyan-600: #00a0b4;
  --cyan-700: #008494;
  --cyan-800: #006775;
  --cyan-900: #004b55;
  --pink-50: #fef4f7;
  --pink-100: #fac9da;
  --pink-200: #f69ebc;
  --pink-300: #f1749e;
  --pink-400: #ed4981;
  --pink-500: #e91e63;
  --pink-600: #c61a54;
  --pink-700: #a31545;
  --pink-800: #801136;
  --pink-900: #5d0c28;
  --indigo-50: #f5f6fb;
  --indigo-100: #d1d5ed;
  --indigo-200: #ab92c5; // Custom colour
  --indigo-300: #8893d1;
  --indigo-400: #735ba7; // Custom colour
  --indigo-500: #57318c; // Custom colour
  --indigo-600: #36459a;
  --indigo-700: #2c397f;
  --indigo-800: #232d64;
  --indigo-900: #192048;
  --teal-50: #f2faf9;
  --teal-100: #c2e6e2;
  --teal-200: #91d2cc;
  --teal-300: #61beb5;
  --teal-400: #30aa9f;
  --teal-500: #009688;
  --teal-600: #008074;
  --teal-700: #00695f;
  --teal-800: #00534b;
  --teal-900: #003c36;
  --orange-50: #fff8f2;
  --orange-100: #fde0c2;
  --orange-200: #fbc791;
  --orange-300: #f9ae61;
  --orange-400: #f79530;
  --orange-500: #f57c00;
  --orange-600: #d06900;
  --orange-700: #ac5700;
  --orange-800: #874400;
  --orange-900: #623200;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #faf4fb;
  --purple-100: #e7cbec;
  --purple-200: #d4a2dd;
  --purple-300: #c279ce;
  --purple-400: #af50bf;
  --purple-500: #9c27b0;
  --purple-600: #852196;
  --purple-700: #6d1b7b;
  --purple-800: #561561;
  --purple-900: #3e1046;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
}

@media print {
  mat-drawer-container,
  mat-drawer-content {
    overflow: visible !important;
  }
}

.grabbable {
  cursor: grab;
}

.grabbable:active {
  cursor: grabbing;
}

.remove-expansion-panel-body-padding div.mat-expansion-panel-body {
  padding: 0 !important;
}

.grey-button {
  color: var(--brand-color-grey);

  &:hover {
    color: var(--brand-color-grey-darker-10);
  }
}

.green-button {
  color: var(--brand-color-grey);

  &:hover {
    color: var(--brand-color-grey-darker-10);
  }
}

// filter-multiselect (also grouped one) panelClass
.filter-multiselect-panel {
  min-width: auto;
  min-width: calc(100% + 50px);
  max-width: 350px;
  max-height: 50vh !important;

  mat-expansion-panel-header > span {
    align-items: center;
  }

  .filter-option span {
    margin-left: 20px;
  }

  ngx-mat-select-search mat-checkbox {
    --mdc-list-list-item-disabled-label-text-opacity: 1;
  }

  mat-checkbox div.mdc-form-field {
    --mdc-typography-body2-font-size: 16px;
    --mdc-typography-body2-line-height: 24px;
    --mdc-form-field-label-text-size: 16px;
    --mdc-form-field-label-text-line-height: 24px;
  }
}

button.mat-green {
  --mdc-fab-container-color: var(--brand-color-light-green);
  --mat-mdc-fab-color: var(--brand-color-white);
  &:hover:enabled {
    background-color: var(--brand-color-light-green-darker-10);
  }
  &:disabled {
    background-color: var(--brand-color-light-green-lighter-75);
  }
}

mat-list-option.grey-checkbox {
  --mdc-checkbox-selected-icon-color: #b0b0b0;
  --mdc-checkbox-selected-hover-icon-color: #b0b0b0;
  --mdc-checkbox-selected-pressed-icon-color: #b0b0b0;
}

mat-selection-list {
  --mdc-typography-body2-font-weight: 500;
}

// edit-list mat-list-item component custom styling
.edit-list-item {
  mat-form-field {
    div.mdc-text-field {
      padding-left: 0;
    }
    div.mat-mdc-form-field-infix {
      padding: 0.5rem 0 !important;
      min-height: auto !important;
    }
  }
}

//used for smaller mat-icon-buttons in list items
button.list-item-button {
  color: #999;
  height: 2rem;
  width: 2rem;
  &:hover {
    color: #222;
  }
  span.mat-mdc-button-touch-target {
    height: 2rem;
    width: 2rem;
  }
}

//slider-form-field component
mat-slider.slider {
  mat-slider-visual-thumb {
    top: -12px;
  }
}

.container,
.container-fluid {
  .row:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.no-max-width {
  max-width: none !important;
}
