.seDialog {
	mat-dialog-container {
		padding: 0;
		--mdc-dialog-container-shape: 0;

		header {
			background-color: var(--brand-color);
			min-height: 3.5rem;
			padding: 0 0.75rem 0 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;

			h2 {
				text-transform: uppercase;
				font-size: 10pt;
				line-height: 24px;
				padding: 0;
				margin: 0;
				font-weight: bold;
			}

			button mat-icon:hover {
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.2);
			}
		}

		content {
			display: block;
			max-height: 80vh;
			overflow-y: scroll;
			padding: 1rem;

			// Bar
			&::-webkit-scrollbar {
				width: 5px;
			}
			// Track
			&::-webkit-scrollbar-track {
				background: var(--brand-color-light-grey);
			}
			// Handle
			&::-webkit-scrollbar-thumb {
				background: var(--brand-color-medium);

				&:hover {
					background: var(--brand-color-medium);
				}
			}

			mat-divider {
				margin-bottom: 0.5rem;
			}

			section:not(:first-child) {
				margin-top: 1rem;
			}

			.help-formatting {
				h3 {
					font-weight: 700;
					text-transform: uppercase;
					color: var(--brand-color);
				}

				h4 {
					font-weight: 500;
					margin: 0;
					color: var(--brand-color);
				}

				p:last-child {
					margin-bottom: 0;
				}
			}

			.helpTable {
				width: 100%;
				table-layout: fixed;
				tr {
					td {
						vertical-align: top;
						padding: 7px;

						ul {
							margin: 0;
							padding: 0;
							list-style: none;
						}

						&:nth-child(1) {
							font-weight: bold;
							min-width: 200px;
							max-width: 200px;
						}
					}

					&:nth-child(odd) {
						background-color: #f0f0f0;
					}
				}
			}
		}

		footer {
			display: flex;
			justify-content: flex-end;
			padding: 0.5rem 1.5rem;
			gap: 0.5rem;
			background-color: #fafafa;
			border-top: 1px solid #ddd;
		}
	}
}
